body {
  overflow: hidden;
  height: 100%;
}

#root {
  height: 100vh;
  overflow: auto;
}

html {
  --scrollbarBG: transparent;
  --thumbBG: rgba(144, 193, 219, 0.6);
}
body::-webkit-scrollbar {
  width: 11px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
